import React from 'react'
import * as Styled from './Row.styles'

export const Row = ({
  alignBottom,
  alignMiddle,
  alignJustify,
  alignSpaced,
  alignRight,
  rowInRow,
  column,
  largeTextCenter,
  mobileNoSpacing,
  children,
}) => (
  <Styled.Row
    alignBottom={alignBottom}
    alignMiddle={alignMiddle}
    alignJustify={alignJustify}
    alignSpaced={alignSpaced}
    alignRight={alignRight}
    column={column}
    largeTextCenter={largeTextCenter}
    mobileNoSpacing={mobileNoSpacing}
    rowInRow={rowInRow}
  >
    {children}
  </Styled.Row>
)

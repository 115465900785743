import styled, { css } from 'styled-components'

const Column = styled.div`
  flex: 1 1 0;
  padding-right: ${props => props.theme.grid.spacing};
  padding-left: ${props => props.theme.grid.spacing};
  min-width: 0;

  ${props =>
    props.xs &&
    css`
      flex: 0 0 ${(props.xs / 12) * 100}%;
      max-width: ${(props.xs / 12) * 100}%;
    `}

  ${props =>
    props.sm &&
    css`
      @media (min-width: ${props.theme.breakpoints.smUp}) {
        flex: 0 0 ${(props.sm / 12) * 100}%;
        max-width: ${(props.sm / 12) * 100}%;
      }
    `}

  ${props =>
    props.md &&
    css`
      @media (min-width: ${props.theme.breakpoints.mdUp}) {
        flex: 0 0 ${(props.md / 12) * 100}%;
        max-width: ${(props.md / 12) * 100}%;
      }
    `}

  ${props =>
    props.lg &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        flex: 0 0 ${(props.lg / 12) * 100}%;
        max-width: ${(props.lg / 12) * 100}%;
      }
    `}

  ${props =>
    props.xl &&
    css`
      @media (min-width: ${props.theme.breakpoints.xlUp}) {
        flex: 0 0 ${(props.xl / 12) * 100}%;
        max-width: ${(props.xl / 12) * 100}%;
      }
    `}

  ${props =>
    props.largeOrder &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        order: ${props.largeOrder};
      }
    `}

  ${props =>
    props.largeOffset &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        margin-left: ${(props.largeOffset / 12) * 100}%;
      }
    `}

  ${props =>
    props.largeShrink &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        flex: 0 0 auto;
        max-width: 100%;
      }
    `}

  ${props =>
    props.largeTextCenter &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        text-align: center;
      }
    `}

  
  
  ${props =>
    props.textCenter &&
    css`
      text-align: center;
    `}

  ${props =>
    props.textRight &&
    css`
      text-align: right;
    `}

  ${props =>
    props.noPaddingMobile &&
    css`
      @media (max-width: ${props.theme.breakpoints.lg}) {
        padding: 0 !important;
      }
    `}
`

export { Column }

import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const styles = css`
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: ${props => props.theme.fontSize.base};
  padding: 12px;
  border-radius: 20px;
  min-width: 230px;
  transition: 0.2s background-color, 0.2s color;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: ${props => props.theme.fontSize.lg};
    padding: 14px;
    border-radius: 25px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    font-size: ${props => props.theme.fontSize.xxl};
  }

  ${props =>
    props.primary &&
    css`
      color: #fff;
      background-color: ${props.theme.colors.primary};

      &:hover {
        background-color: ${props.theme.colors.primaryHover};
      }
    `}

  ${props =>
    props.bordered &&
    css`
      color: #fff;
      background-color: ${props.theme.colors.primary};
      border: 2px solid #fff;

      &:hover {
        background-color: #fff;
        color: ${props.theme.colors.primary};
      }
    `}
  
  ${props =>
    props.light &&
    css`
      color: ${props.theme.colors.dark};
      background-color: ${props.theme.colors.light};

      &:hover {
        background-color: #fff;
      }
    `}
`

const Button = styled.button`
  ${styles}
`

const A = styled.a`
  ${styles}
`

const GatsbyLink = styled(Link)`
  ${styles}
`

export { Button, A, GatsbyLink }

import styled, { css } from 'styled-components'

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 15px;

  input,
  textarea,
  select {
    background-color: transparent;
    color: ${props => props.theme.colors.dark};

    &:focus,
    &:hover {
      //border-color: ${props => props.theme.colors.primary};
      outline: 0;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }
  }

  select {
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    background-size: 0.6em;
    background-position: calc(100% - 1em) center;
    background-repeat: no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  input:not([type='checkbox']):not([type='radio']),
  textarea,
  select {
    width: 100%;
    display: block;
    padding: 12px 0 6px;
    appearance: none;
    border: 0;
    border-bottom: 2px solid ${props => props.theme.colors.light};
    transition: 0.2s border-color;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 16px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      font-size: 18px;
    }

    &:focus,
    &:not(:placeholder-shown) {
      & ~ label {
        transform: translateY(-12px) scale(0.7);
        opacity: 0.7;
      }
    }

    & + label {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 0 6px;
      font-weight: 300;
      pointer-events: none;
      transform-origin: 0 0;
      transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    }

    &::placeholder {
      color: transparent;
    }
  }

  //custom for consultation section in footer
  &.consultation {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      flex: 0 0 calc(100% - 240px);
    }
    input {
      border-radius: 20px;
      background-color: #fff;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        border-radius: 37px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding: 24px !important;
      }
    }

    label {
      color: ${props => props.theme.colors.primary};
      font-weight: 500 !important;
      padding-left: 15px !important;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        padding: 22px 30px !important;
        font-size: 20px !important;
      }
    }
  }

  ${props =>
    props.light &&
    css`
      label {
        color: #fff;
      }
    `}

  &.checkbox-wrapper {
    display: flex;
    align-content: center;
    flex-direction: row;
    margin-top: 10px;

    &.centered {
      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        line-height: 30px;
      }

      [role='alert'] {
        bottom: -6px;
      }
    }

    &.small {
      display: block;

      [type='checkbox'] {
        float: left;
        margin-bottom: 16px;
      }

      label {
        font-size: 12px;
        line-height: 1.2;

        @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
          font-size: 14px;
        }
      }
    }

    &.white {
      label {
        color: #fff;
      }

      [type='checkbox'] {
        background-color: #fff;
      }
    }

    label {
      margin: 0;
      order: unset;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        font-size: 16px;
      }
    }
  }

  [type='checkbox'] {
    outline: none;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-right: 20px;
    background-color: ${props => props.theme.colors.light};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 2px solid ${props => props.theme.colors.light};
    appearance: none;
    color-adjust: exact;

    &:checked {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNC41ODciIGhlaWdodD0iMjAuNTkiIHZpZXdCb3g9IjAgMCAzNC41ODcgMjAuNTkiPgogIDxwYXRoIGlkPSJQYXRoXzI1MjQwIiBkYXRhLW5hbWU9IlBhdGggMjUyNDAiIGQ9Ik0tMTIzMy4yMjIsMTIwNi41ODFsOS41NTgsOC40NzgsMjMuMTMtMTcuNDU1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjM0LjIxOCAtMTE5Ni40MDYpIiBmaWxsPSJub25lIiBzdHJva2U9IiMxMDI0M2MiIHN0cm9rZS13aWR0aD0iMyIvPgo8L3N2Zz4K');

      & + label {
        font-weight: 400;
      }
    }
  }

  textarea {
    resize: none;
    height: 8em;
    line-height: 2.5em;
    background-image: linear-gradient(
      ${props => props.theme.colors.light} 2px,
      transparent 2px
    );
    background-size: 100% 2.7em;
    background-position: 50% 100%;
  }

  &.filed-error {
    input,
    textarea {
      border-color: ${props => props.theme.colors.error} !important;

      &:not(:disabled):focus,
      &:not(:disabled):hover {
        border-color: ${props => props.theme.colors.primaryHover} !important;
      }
    }
  }
`

const FieldLabel = styled.label`
  position: relative;
  order: -1;
  display: block;
  margin-bottom: 4px;
  color: ${props => props.theme.colors.dark};

  a {
    font-weight: 700;
  }
`

const FieldMsg = styled.small`
  position: absolute;
  left: 0;
  bottom: -2px;
  font-size: 12px;
  color: ${props => props.theme.colors.error};
`

export { InputWrapper, FieldLabel, FieldMsg }

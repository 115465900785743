import React from 'react'

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="148"
    height="46.844"
    viewBox="0 0 148 46.844"
  >
    <g>
      <path
        d="M85.856,158.605l2.919-2.936c-.793-.792-1.487-1.484-1.991-1.99a14.9,14.9,0,0,1-4.276-10.622,15.24,15.24,0,0,1,30.48,0,15.5,15.5,0,0,1-1.208,6.047l3.078,3.084a19.358,19.358,0,1,0-36.44-9.131,19.09,19.09,0,0,0,5.389,13.476C84.342,157.078,85.047,157.79,85.856,158.605Z"
        transform="translate(-78.417 -123.726)"
        className="logo-part part-top"
        fill="#0a243e"
      />
      <path
        d="M107.146,146.052c-.263.313-.539.62-.831.913-2.733,2.741-10.964,10.909-10.964,10.909s-3.026-3-6.016-5.977l-2.944,2.937c4.17,4.181,8.96,8.953,8.96,8.953s10.494-10.459,13.941-13.969c.271-.276.529-.563.782-.856Z"
        transform="translate(-76.019 -117.012)"
        className="logo-part part-bottom"
        fill="#fff"
      />
    </g>
    <g transform="translate(54.66 3.841)">
      <path
        d="M131.108,127.145h3.284v16.539h-3.355L123.76,132.7H123.7v10.981h-3.262V127.145h3.278l7.379,11.173h.012Z"
        transform="translate(-120.439 -126.539)"
        fill="#fff"
      />
      <path
        d="M139.312,142.718a5.932,5.932,0,0,1-4.355-1.653,6.14,6.14,0,0,1,0-8.433,5.933,5.933,0,0,1,4.355-1.655,6.032,6.032,0,0,1,4.356,1.631,6.132,6.132,0,0,1,0,8.478A6.032,6.032,0,0,1,139.312,142.718Zm0-2.725a2.805,2.805,0,0,0,2.108-.886,3.459,3.459,0,0,0,.012-4.53,2.81,2.81,0,0,0-2.12-.873,2.764,2.764,0,0,0-2.131.9,3.433,3.433,0,0,0,.012,4.484A2.759,2.759,0,0,0,139.312,139.992Z"
        transform="translate(-116.577 -125.386)"
        fill="#fff"
      />
      <path
        d="M150.976,130.977a.676.676,0,0,1,.233.023v3.309c-.2-.016-.466-.025-.792-.025a2.778,2.778,0,0,0-2.142.827,3,3,0,0,0-.769,2.131v5.289h-3.052V131.164h3.052v1.677h.023A4.057,4.057,0,0,1,150.976,130.977Z"
        transform="translate(-113.217 -125.386)"
        fill="#fff"
      />
      <path
        d="M159.342,126.679h3.052v17.145h-2.911v-1.375h-.047a4.2,4.2,0,0,1-3.5,1.561,5.157,5.157,0,0,1-3.913-1.619,5.885,5.885,0,0,1-1.537-4.227,6.017,6.017,0,0,1,1.526-4.229,4.98,4.98,0,0,1,3.832-1.665,4.278,4.278,0,0,1,3.425,1.467h.069ZM154.4,140.424a2.763,2.763,0,0,0,2.1.861,2.913,2.913,0,0,0,2.142-.861,3.027,3.027,0,0,0,.861-2.237,3.21,3.21,0,0,0-.849-2.283A2.823,2.823,0,0,0,156.5,135a2.748,2.748,0,0,0-2.086.873,3.233,3.233,0,0,0-.827,2.318A3.125,3.125,0,0,0,154.4,140.424Z"
        transform="translate(-111.401 -126.679)"
        fill="#fff"
      />
      <path
        d="M163.73,130.747a1.833,1.833,0,0,1-1.328-.548,1.829,1.829,0,0,1,0-2.633,1.838,1.838,0,0,1,1.328-.548,1.86,1.86,0,0,1,1.35.548,1.829,1.829,0,0,1,0,2.633A1.855,1.855,0,0,1,163.73,130.747Zm-1.537,12.975V132.355h3.052v11.367Z"
        transform="translate(-107.987 -126.577)"
        fill="#fff"
      />
      <path
        d="M172.31,142.718a5.972,5.972,0,0,1-4.179-10.109,5.91,5.91,0,0,1,4.321-1.631,6.7,6.7,0,0,1,2.7.559,5.482,5.482,0,0,1,2.12,1.608l-2.05,1.769a3.362,3.362,0,0,0-2.656-1.21,2.9,2.9,0,0,0-2.19.873,3.111,3.111,0,0,0-.838,2.248,3.15,3.15,0,0,0,.851,2.283,2.911,2.911,0,0,0,2.2.886,3.368,3.368,0,0,0,2.655-1.165l2.073,1.841a5.824,5.824,0,0,1-2.085,1.479A7.161,7.161,0,0,1,172.31,142.718Z"
        transform="translate(-106.597 -125.386)"
        fill="#fff"
      />
      <path
        d="M120.439,159.873V143.334h3.284v13.628h7.361v2.911Z"
        transform="translate(-120.439 -121.67)"
        fill="#fff"
      />
      <path
        d="M135.928,158.908a5.936,5.936,0,0,1-4.356-1.655,6.14,6.14,0,0,1,0-8.433,5.936,5.936,0,0,1,4.356-1.655,6.031,6.031,0,0,1,4.355,1.631,6.133,6.133,0,0,1,0,8.48A6.031,6.031,0,0,1,135.928,158.908Zm0-2.726a2.8,2.8,0,0,0,2.109-.886,3.463,3.463,0,0,0,.012-4.53,2.819,2.819,0,0,0-2.12-.873,2.767,2.767,0,0,0-2.132.9,3.433,3.433,0,0,0,.012,4.484A2.759,2.759,0,0,0,135.928,156.181Z"
        transform="translate(-117.595 -120.518)"
        fill="#fff"
      />
      <path
        d="M149.3,147.353h3.028v10.063q0,6.1-5.917,6.1a9.546,9.546,0,0,1-4.683-1.142l.722-2.585a7.538,7.538,0,0,0,3.681,1.071,3.586,3.586,0,0,0,2.387-.722,2.28,2.28,0,0,0,.851-1.817v-1.141H149.3A4.259,4.259,0,0,1,146,158.511a5.221,5.221,0,0,1-3.9-1.561,5.515,5.515,0,0,1-1.526-4.03,5.863,5.863,0,0,1,1.491-4.134,4.908,4.908,0,0,1,3.773-1.621,4.614,4.614,0,0,1,3.4,1.353h.07Zm-4.822,7.605a2.829,2.829,0,0,0,2.072.8,2.749,2.749,0,0,0,2.062-.827,2.93,2.93,0,0,0,.8-2.132,2.763,2.763,0,0,0-.814-2.072,2.878,2.878,0,0,0-4.938,2.1A2.856,2.856,0,0,0,144.483,154.959Z"
        transform="translate(-114.385 -120.518)"
        fill="#fff"
      />
      <path
        d="M153.63,146.937a1.839,1.839,0,0,1-1.329-.548,1.833,1.833,0,0,1,0-2.634,1.839,1.839,0,0,1,1.329-.548,1.863,1.863,0,0,1,1.35.548,1.83,1.83,0,0,1,0,2.634A1.863,1.863,0,0,1,153.63,146.937Zm-1.539,12.974V148.544h3.053v11.367Z"
        transform="translate(-111.024 -121.708)"
        fill="#fff"
      />
      <path
        d="M160.725,158.908a6.859,6.859,0,0,1-4.612-1.655l1.489-2.12a4.455,4.455,0,0,0,3.145,1.328,1.781,1.781,0,0,0,1.025-.256.783.783,0,0,0,.372-.675.735.735,0,0,0-.441-.628,9.173,9.173,0,0,0-1.631-.631,7.324,7.324,0,0,1-2.574-1.385,2.954,2.954,0,0,1-.92-2.317,3.012,3.012,0,0,1,1.164-2.458,4.516,4.516,0,0,1,2.934-.944,6.552,6.552,0,0,1,4.147,1.422l-1.467,2.236a3.929,3.929,0,0,0-2.726-1.211,1.408,1.408,0,0,0-.767.221.715.715,0,0,0-.35.64.737.737,0,0,0,.407.594,7.417,7.417,0,0,0,1.316.618c.622.233,1.08.408,1.375.523a9.219,9.219,0,0,1,1.047.514,2.836,2.836,0,0,1,.852.652,3.315,3.315,0,0,1,.442.827,3.487,3.487,0,0,1-.931,3.727A4.9,4.9,0,0,1,160.725,158.908Z"
        transform="translate(-109.71 -120.518)"
        fill="#fff"
      />
      <path
        d="M168.636,159.8a3.039,3.039,0,0,1-2.378-.956,4.083,4.083,0,0,1-.861-2.819v-5.264h-1.351v-2.516H165.4v-3.727l3.028-.326v4.053h3.1v2.516h-3.1v4.845q0,1.444,1,1.445a3.275,3.275,0,0,0,1.444-.4l.793,2.447A6.341,6.341,0,0,1,168.636,159.8Z"
        transform="translate(-107.324 -121.411)"
        fill="#fff"
      />
      <path
        d="M173.258,146.937a1.839,1.839,0,0,1-1.329-.548,1.833,1.833,0,0,1,0-2.634,1.839,1.839,0,0,1,1.329-.548,1.863,1.863,0,0,1,1.35.548,1.833,1.833,0,0,1,0,2.634A1.863,1.863,0,0,1,173.258,146.937Zm-1.539,12.974V148.544h3.053v11.367Z"
        transform="translate(-105.121 -121.708)"
        fill="#fff"
      />
      <path
        d="M181.838,158.908a5.974,5.974,0,0,1-4.181-10.111,5.912,5.912,0,0,1,4.321-1.631,6.7,6.7,0,0,1,2.7.559,5.454,5.454,0,0,1,2.12,1.609l-2.05,1.769a3.363,3.363,0,0,0-2.656-1.211,2.906,2.906,0,0,0-2.19.873,3.12,3.12,0,0,0-.838,2.249,3.154,3.154,0,0,0,.851,2.282,2.909,2.909,0,0,0,2.2.886,3.373,3.373,0,0,0,2.655-1.164l2.072,1.841a5.828,5.828,0,0,1-2.084,1.478A7.161,7.161,0,0,1,181.838,158.908Z"
        transform="translate(-103.732 -120.518)"
        fill="#fff"
      />
      <path
        d="M189.861,158.908a6.862,6.862,0,0,1-4.612-1.655l1.491-2.12a4.452,4.452,0,0,0,3.145,1.328,1.781,1.781,0,0,0,1.025-.256.786.786,0,0,0,.372-.675.734.734,0,0,0-.442-.628,9.089,9.089,0,0,0-1.631-.631,7.329,7.329,0,0,1-2.573-1.385,2.954,2.954,0,0,1-.92-2.317,3.014,3.014,0,0,1,1.163-2.458,4.522,4.522,0,0,1,2.936-.944,6.552,6.552,0,0,1,4.147,1.422l-1.467,2.236a3.932,3.932,0,0,0-2.726-1.211,1.415,1.415,0,0,0-.769.221.716.716,0,0,0-.349.64.737.737,0,0,0,.407.594,7.417,7.417,0,0,0,1.316.618c.62.233,1.08.408,1.375.523a9.22,9.22,0,0,1,1.047.514,2.811,2.811,0,0,1,.851.652,3.274,3.274,0,0,1,.442.827,3.481,3.481,0,0,1-.93,3.727A4.9,4.9,0,0,1,189.861,158.908Z"
        transform="translate(-100.948 -120.518)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default Logo

import styled from 'styled-components'

const Footer = styled.footer`
  background-color: ${props => props.theme.colors.secondary};
  color: #fff;

  p + p {
    margin-bottom: 30px;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      padding-bottom: 100px;
    }
  }
`

const Copyright = styled.p`
  padding: 20px 0;
  margin: 10px 0 0;
  border-top: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.light};
  text-transform: uppercase;
  font-size: 10px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 30px 0;
    margin: 20px 0 0;
    font-size: 12px;
  }
`

const Slogan = styled.p`
  padding: 55px 0 50px;
  margin: 0;
  color: #fff;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 50px;
    padding: 200px 0 130px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    font-size: 60px;
  }

  span {
    color: ${props => props.theme.colors.primary};
  }
`

const Title = styled.p`
  margin-bottom: 1em;
  color: ${props => props.theme.colors.primary};
`

const SocialMediaList = styled.ul`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-bottom: 25px;
  }

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    text-align: right;
  }

  li {
    display: inline-block;
  }

  a {
    margin: 0 15px;
  }
`

export { Footer, Copyright, Slogan, Title, SocialMediaList }

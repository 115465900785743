import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'
import CookieConsent from 'react-cookie-consent'
import * as Styled from './Layout.styles'
import Header from '../Header'
import Footer from '../Footer'
import Consultation from '../Consultation'
import themes from '../../config/theme'
import GlobalStyle from '../GlobalStyle'

const Layout = ({ children, ...props }) => (
  <ThemeProvider theme={themes.base}>
    <Styled.Layout before={props.before || false} after={props.after || false}>
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
      >
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />

        {/* NO INDEX */}
        {/* <meta name="robots" content="noindex" /> */}
        {/* <meta name="googlebot" content="noindex" /> */}
        {/* <meta name="googlebot-news" content="noindex" /> */}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <title>{props.metaTitle} - Nordic Logistics</title>
        <meta name="description" content={props.metaDescription} />
        {/* <meta name="author" content={props.metaAuthor} /> */}
        <meta
          property="og:title"
          content={`${props.metaTitle} - Nordic Logistics`}
        />
        <meta property="og:description" content={props.metaDescription} />
        <meta property="og:image" content={props.metaImageSrc} />
        <meta property="og:image:alt" content={props.metaImageAlt} />
        <meta name="twitter:card" content={props.metaImageSrc} />
        <meta
          property="og:url"
          content={`https://www.nordiclogistics.pl${props.slug}`}
        />
        <link
          rel="canonical"
          href={`https://www.nordiclogistics.pl${props.slug}`}
        />
        <link
          rel="preload"
          href="/fonts/montserrat/montserrat-v18-latin-ext-300.woff2"
          as="font"
          type="font/woff2"
          crossOrigin
        />
        <link
          rel="preload"
          href="/fonts/montserrat/montserrat-v18-latin-ext-500.woff2"
          as="font"
          type="font/woff2"
          crossOrigin
        />
        <link
          rel="preload"
          href="/fonts/montserrat/montserrat-v18-latin-ext-600.woff2"
          as="font"
          type="font/woff2"
          crossOrigin
        />
      </Helmet>
      <GlobalStyle />
      <Header slug={props.slug} reverseColors={props.reverseHeaderColors} />
      {props.slug !== '/kontakt' && (
        <Styled.ScrollDown
          href="#czytaj-wiecej"
          onClick={e => {
            e.preventDefault()
            scrollTo('#czytaj-wiecej')
          }}
        >
          <span className="sr-only">Przewiń na dół</span>
        </Styled.ScrollDown>
      )}
      <Styled.SocialMediaList>
        <li>
          <Styled.Fb
            href="https://www.facebook.com/NordicTeamPL/"
            target="_blank"
            rel="nofollow noopener noreferrer"
            reverseColors={props.reverseHeaderColors}
          >
            <span className="sr-only">Facebook Nordic Team</span>
          </Styled.Fb>
        </li>
        <li>
          <Styled.Ig
            href="https://www.instagram.com/nordic_logistics_team/"
            target="_blank"
            rel="nofollow noopener noreferrer"
            reverseColors={props.reverseHeaderColors}
          >
            <span className="sr-only">Instagram Nordic Team</span>
          </Styled.Ig>
        </li>
        <li>
          <Styled.In
            href="https://pl.linkedin.com/company/nordic-logistics-polska"
            target="_blank"
            rel="nofollow noopener noreferrer"
            reverseColors={props.reverseHeaderColors}
          >
            <span className="sr-only">Linkedin NordicLogistics Polska</span>
          </Styled.In>
        </li>
      </Styled.SocialMediaList>
      <main>
        {children}
        {!props.hideForm && <Consultation />}
      </main>
      <Footer />
      <CookieConsent
        containerClasses="cookies-box"
        buttonClasses="cookies-button"
        buttonText={<span className="sr-only">OK</span>}
        disableStyles
      >
        <Styled.CookiesText>
          Ta witryna internetowa używa plików cookie, które poprawiają Twój
          komfort korzystania z Internetu, gdyż umożliwają nam dostosowywanie
          treści do Twoich zainteresowań i zwyczajów podczas przeglądania sieci.
          Poprzez dalsze korzystanie z naszej Witryny internetowej udzielasz nam
          zgody na użycie plików cookie. Aby uzyskać informacje dotyczące plików
          cookie i szczegółowe informacje dotyczące sposobu wykorzystywania
          Twoich danych osobowych, a także informacje o sposobie wycofania
          swojej zgody (co możesz zrobić w dowolnym czasie), zapoznaj się z
          naszą stroną Polityki Prywatności.
        </Styled.CookiesText>
      </CookieConsent>
    </Styled.Layout>
  </ThemeProvider>
)

export default Layout

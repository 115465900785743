import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const Header = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 15px 0;
  background-color: transparent;
  z-index: 9;
  transition: 0.2s background-color, 0.2s padding;

  svg {
    width: 148px;
    height: auto;

    @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
      width: 180px;
    }
    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      width: 234px;
    }
  }

  ${props =>
    props.reverseColors === true &&
    !props.scrolled &&
    css`
      a:not(.button) {
        color: ${props.theme.colors.dark};
      }

      svg {
        path {
          fill: ${props.theme.colors.dark};
        }

        .part-top {
          fill: ${props.theme.colors.primary};
        }

        .part-bottom {
          fill: ${props.theme.colors.dark};
        }
      }
    `}

  ${props =>
    props.scrolled === true &&
    css`
      padding: 10px 0;
      background-color: ${props.theme.colors.secondary};

      svg {
        path {
          transition: 0.2s fill;
        }

        .part-top {
          fill: #fff;
        }

        .part-bottom {
          fill: ${props.theme.colors.primary};
        }
      }
    `}
`

const Toggler = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0;
  z-index: 10;
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
    display: none;
  }

  .toggler-helper {
    display: inline-block;
    position: relative;
    height: 4px;
    width: 28px;
    background: #fff;
    border-radius: 4px;
    z-index: 3;
    transition: 0.2s;

    &:after,
    &:before {
      content: '';
      position: absolute;
      z-index: 3;
      background: #fff;
      height: 4px;
      width: 28px;
      right: 0;
      border-radius: 4px;
      transition: 0.2s;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }
  }

  ${props =>
    props.active === true &&
    css`
      .toggler-helper {
        background: transparent !important;

        &:before {
          transform: translate3d(0, 8px, 0) rotate(45deg);
        }

        &:after {
          transform: translate3d(0, -8px, 0) rotate(-45deg);
        }
      }
    `}

  &:not(.active):hover {
    background-color: ${props => props.theme.colors.primaryHover};
  }
`

const Nav = styled.nav`
  @media (max-width: ${props => props.theme.breakpoints.xl}) {
    position: fixed;
    width: 100%;
    right: 100%;
    top: 70px;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.secondary};
    text-align: left;
    padding: 20px;
    transition: 0.2s right;

    ${props =>
      props.opened === true &&
      css`
        right: 0;
      `}
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    text-align: center;
    justify-content: center;
  }
  @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
    margin-right: 10px;
  }
`

const NavList = styled.ul`
  @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
    display: inline-block;
  }

  li {
    @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
      display: inline-block;
    }

    &:last-child {
      @media (max-width: ${props => props.theme.breakpoints.xl}) {
        //margin-top: 10px;
        display: none;
      }
      @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
        margin-left: 10px;
      }
      @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
        margin-left: 24px;
      }
    }
  }
`

const NavLink = styled(Link)`
  display: inline-block;
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  margin: 3px;
  transition: 0.2s color;

  @media (min-width: ${props => props.theme.breakpoints.xlUp}) {
    font-size: 18px;
    padding: 10px 12px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    font-size: 20px;
    padding: 10px 24px;
  }

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

export { Header, Toggler, Nav, NavList, NavLink }

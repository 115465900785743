import styled, { css } from 'styled-components'

export const Row = styled.div`
  width: 92%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    width: 90%;
  }

  // .row .row
  & & {
    width: auto;
    max-width: none;
    margin-right: -${props => props.theme.grid.spacing};
    margin-left: -${props => props.theme.grid.spacing};
  }

  ${props =>
    props.alignMiddle &&
    css`
      align-items: center;
    `}

  ${props =>
    props.alignBottom &&
    css`
      align-items: flex-end;
    `}

  ${props =>
    props.alignJustify &&
    css`
      justify-content: space-between;
    `}

  ${props =>
    props.alignSpaced &&
    css`
      justify-content: space-around;
    `}

  ${props =>
    props.alignRight &&
    css`
      justify-content: flex-end;
    `}

  // .row.column
  ${props =>
    props.column &&
    css`
      float: none;
      display: block;
      padding-right: ${props.theme.grid.spacing};
      padding-left: ${props.theme.grid.spacing};
    `}

  ${props =>
    props.largeTextCenter &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        text-align: center;
      }
    `}

  ${props =>
    props.mobileNoSpacing &&
    css`
      @media (max-width: ${props.theme.breakpoints.lg}) {
        margin: 0;
        padding: 0;
        width: auto;
      }
    `}
`

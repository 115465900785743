const base = {
  colors: {
    primary: '#426D9B',
    primaryHover: '#668EB4',
    secondary: '#0A243E',
    addon: '#7C0A17',
    light: '#DEE9EE',
    dark: '#00151F',
    error: '#ff5b6e',
  },
  breakpoints: {
    xs: '0',
    sm: '576px',
    smUp: '577px',
    md: '768px',
    mdUp: '769px',
    lg: '1024px',
    lgUp: '1025px',
    xl: '1200px',
    xlUp: '1201px',
    xxl: '1440px',
    xxlUp: '1441px',
  },
  fontSize: {
    base: '14px',
    lg: '16px',
    xxl: '18px',
  },
  grid: {
    spacing: '20px',
    sideMargin: '4vw',
  },
}

export default {
  base,
}

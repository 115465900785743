import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const Consultation = styled(BackgroundImage)`
  color: #fff;
  padding: 30px 0;
  position: relative;
  z-index: 1;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 70px 0 30px;
  }

  .gatsby-image-wrapper {
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translateY(23%);
  }

  h2 {
    color: #fff;
  }
`

const FormWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    width: 75%;
    margin-bottom: 25px;
  }
  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    display: flex;
    align-items: center;
  }
`

const Button = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  padding: 12px;
  letter-spacing: 0.1em;
  border-radius: 20px;
  transition: 0.2s background-color;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    background-color: ${props => props.theme.colors.secondary};
    margin-bottom: 25px; // for centering
    font-size: 16px;
    width: 240px;
    padding: 26px;
    border-radius: 37px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    font-size: 18px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.dark};
  }
`

export { Consultation, FormWrapper, Button }

import styled, { css } from 'styled-components'

const Layout = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    &:before,
    &:after {
      position: absolute;
      font-size: 400px;
      line-height: 0.75;
      color: transparent;
      font-weight: 600;
      -webkit-text-stroke: 3px ${props => props.theme.colors.light};
      pointer-events: none;
      z-index: -1;
    }

    ${props =>
      props.before &&
      css`
        &:before {
          content: '${props.before}';
          left: 0;
          top: 45%;
          transform: translateX(-45%) rotate(-90deg);
        }
      `}

    ${props =>
      props.after &&
      css`
        &:after {
          content: '${props.after}';
          right: 0;
          top: 55%;
          transform: translateX(43%) rotate(-90deg);
        }
      `}
  }
`

const ScrollDown = styled.a`
  position: absolute;
  right: 3vw;
  top: calc(100vh - 50px);
  display: inline-block;
  width: 20px;
  height: 100px;
  background: transparent
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi43MjMiIGhlaWdodD0iMTAwLjEyMSIgdmlld0JveD0iMCAwIDIyLjcyMyAxMDAuMTIxIj4KICA8ZyBpZD0iR3JvdXBfMjgxNiIgZGF0YS1uYW1lPSJHcm91cCAyODE2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjM2MSA5OCkgcm90YXRlKC05MCkiPgogICAgPGxpbmUgaWQ9IkxpbmVfMSIgZGF0YS1uYW1lPSJMaW5lIDEiIHgyPSI5OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTggMTApIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBzdHJva2U9IiM2ZThlYjEiIHN0cm9rZS13aWR0aD0iMyIvPgogICAgPHBhdGggaWQ9IlBhdGhfMjUiIGRhdGEtbmFtZT0iUGF0aCAyNSIgZD0iTTE1Ny4zLDExNzguNjc2bC0xMC4zLDEwLjMsMTAuMywxMC4zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTQ3IC0xMTc4Ljk3NikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzZlOGViMSIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgPC9nPgo8L3N2Zz4K')
    no-repeat center / contain;
  transform: translateY(-100%);
  z-index: 8;
  transition: 0.2s transform;

  &:hover {
    transform: translateY(-90%);
  }

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`

const SocialMediaList = styled.ul`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    position: absolute;
    left: 2vw;
    top: calc(100vh - 50px);
    display: inline-block;
    transform: translateY(-100%);
    z-index: 8;
  }
  @media (min-width: 1800px) {
    left: 3vw;
  }
`

const Icon = css`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 10px 0;
  background: transparent none no-repeat center / contain;
`

const Fb = styled.a`
  ${Icon};
  background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iZmFjZWJvb2stbG9nbyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTUuODk4IiBoZWlnaHQ9IjI5LjQwNiIgdmlld0JveD0iMCAwIDE1Ljg5OCAyOS40MDYiPgogIDxwYXRoIGlkPSJQYXRoXzIiIGRhdGEtbmFtZT0iUGF0aCAyIiBkPSJNMzcuMzc3LjAwNiwzMy41NjMsMGMtNC4yODQsMC03LjA1MywyLjg0LTcuMDUzLDcuMjM3djMuMzM3SDIyLjY3N2EuNi42LDAsMCwwLS42LjZ2NC44MzRhLjYuNiwwLDAsMCwuNi42aDMuODM0djEyLjJhLjYuNiwwLDAsMCwuNi42aDVhLjYuNiwwLDAsMCwuNi0uNnYtMTIuMkgzNy4yYS42LjYsMCwwLDAsLjYtLjZsMC00LjgzNGEuNi42LDAsMCwwLS42LS42SDMyLjcxMlY3Ljc0NWMwLTEuMzYuMzI0LTIuMDUsMi4wOTUtMi4wNWgyLjU2OWEuNi42LDAsMCwwLC42LS42Vi42MDZBLjYuNiwwLDAsMCwzNy4zNzcuMDA2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIyLjA3NykiIGZpbGw9IiNkZWU5ZWUiLz4KPC9zdmc+Cg==');

  ${props =>
    props.reverseColors === true &&
    css`
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iZmFjZWJvb2stbG9nbyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTUuODk4IiBoZWlnaHQ9IjI5LjQwNiIgdmlld0JveD0iMCAwIDE1Ljg5OCAyOS40MDYiPgogIDxwYXRoIGlkPSJQYXRoXzIiIGRhdGEtbmFtZT0iUGF0aCAyIiBkPSJNMzcuMzc3LjAwNiwzMy41NjMsMGMtNC4yODQsMC03LjA1MywyLjg0LTcuMDUzLDcuMjM3djMuMzM3SDIyLjY3N2EuNi42LDAsMCwwLS42LjZ2NC44MzRhLjYuNiwwLDAsMCwuNi42aDMuODM0djEyLjJhLjYuNiwwLDAsMCwuNi42aDVhLjYuNiwwLDAsMCwuNi0uNnYtMTIuMkgzNy4yYS42LjYsMCwwLDAsLjYtLjZsMC00LjgzNGEuNi42LDAsMCwwLS42LS42SDMyLjcxMlY3Ljc0NWMwLTEuMzYuMzI0LTIuMDUsMi4wOTUtMi4wNWgyLjU2OWEuNi42LDAsMCwwLC42LS42Vi42MDZBLjYuNiwwLDAsMCwzNy4zNzcuMDA2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIyLjA3NykiIGZpbGw9IiMwMDE1MWYiLz4KPC9zdmc+Cg==');
    `};
`

const Ig = styled.a`
  ${Icon};
  background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iaW5zdGFncmFtIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi43OCIgaGVpZ2h0PSIyMi43OCIgdmlld0JveD0iMCAwIDIyLjc4IDIyLjc4Ij4KICA8ZyBpZD0iR3JvdXBfMTAiIGRhdGEtbmFtZT0iR3JvdXAgMTAiPgogICAgPGcgaWQ9Ikdyb3VwXzkiIGRhdGEtbmFtZT0iR3JvdXAgOSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzMiIGRhdGEtbmFtZT0iUGF0aCAzIiBkPSJNMTUuNjYxLDBINy4xMTlBNy4xMiw3LjEyLDAsMCwwLDAsNy4xMTl2OC41NDJBNy4xMiw3LjEyLDAsMCwwLDcuMTE5LDIyLjc4aDguNTQzYTcuMTIsNy4xMiwwLDAsMCw3LjExOS03LjExOVY3LjExOUE3LjEyLDcuMTIsMCwwLDAsMTUuNjYxLDBabTQuOTgzLDE1LjY2MWE0Ljk4OSw0Ljk4OSwwLDAsMS00Ljk4Myw0Ljk4M0g3LjExOWE0Ljk4OSw0Ljk4OSwwLDAsMS00Ljk4My00Ljk4M1Y3LjExOUE0Ljk4OSw0Ljk4OSwwLDAsMSw3LjExOSwyLjEzNmg4LjU0M2E0Ljk4OSw0Ljk4OSwwLDAsMSw0Ljk4Myw0Ljk4M1oiIGZpbGw9IiNkZWU5ZWUiLz4KICAgIDwvZz4KICA8L2c+CiAgPGcgaWQ9Ikdyb3VwXzEyIiBkYXRhLW5hbWU9Ikdyb3VwIDEyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjY5NSA1LjY5NSkiPgogICAgPGcgaWQ9Ikdyb3VwXzExIiBkYXRhLW5hbWU9Ikdyb3VwIDExIj4KICAgICAgPHBhdGggaWQ9IlBhdGhfNCIgZGF0YS1uYW1lPSJQYXRoIDQiIGQ9Ik0xMzMuNywxMjhhNS42OTUsNS42OTUsMCwxLDAsNS43LDUuNjk1QTUuNyw1LjcsMCwwLDAsMTMzLjcsMTI4Wm0wLDkuMjU0YTMuNTU5LDMuNTU5LDAsMSwxLDMuNTU5LTMuNTU5QTMuNTY0LDMuNTY0LDAsMCwxLDEzMy43LDEzNy4yNTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI4IC0xMjgpIiBmaWxsPSIjZGVlOWVlIi8+CiAgICA8L2c+CiAgPC9nPgogIDxnIGlkPSJHcm91cF8xNCIgZGF0YS1uYW1lPSJHcm91cCAxNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYuNzUzIDQuNTA5KSI+CiAgICA8ZyBpZD0iR3JvdXBfMTMiIGRhdGEtbmFtZT0iR3JvdXAgMTMiPgogICAgICA8ZWxsaXBzZSBpZD0iRWxsaXBzZV8xIiBkYXRhLW5hbWU9IkVsbGlwc2UgMSIgY3g9IjAuNzU5IiBjeT0iMC43NTkiIHJ4PSIwLjc1OSIgcnk9IjAuNzU5IiBmaWxsPSIjZGVlOWVlIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K');

  ${props =>
    props.reverseColors === true &&
    css`
      background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iaW5zdGFncmFtIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi43OCIgaGVpZ2h0PSIyMi43OCIgdmlld0JveD0iMCAwIDIyLjc4IDIyLjc4Ij4KICA8ZyBpZD0iR3JvdXBfMTAiIGRhdGEtbmFtZT0iR3JvdXAgMTAiPgogICAgPGcgaWQ9Ikdyb3VwXzkiIGRhdGEtbmFtZT0iR3JvdXAgOSI+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzMiIGRhdGEtbmFtZT0iUGF0aCAzIiBkPSJNMTUuNjYxLDBINy4xMTlBNy4xMiw3LjEyLDAsMCwwLDAsNy4xMTl2OC41NDJBNy4xMiw3LjEyLDAsMCwwLDcuMTE5LDIyLjc4aDguNTQzYTcuMTIsNy4xMiwwLDAsMCw3LjExOS03LjExOVY3LjExOUE3LjEyLDcuMTIsMCwwLDAsMTUuNjYxLDBabTQuOTgzLDE1LjY2MWE0Ljk4OSw0Ljk4OSwwLDAsMS00Ljk4Myw0Ljk4M0g3LjExOWE0Ljk4OSw0Ljk4OSwwLDAsMS00Ljk4My00Ljk4M1Y3LjExOUE0Ljk4OSw0Ljk4OSwwLDAsMSw3LjExOSwyLjEzNmg4LjU0M2E0Ljk4OSw0Ljk4OSwwLDAsMSw0Ljk4Myw0Ljk4M1oiIGZpbGw9IiMwMDE1MWYiLz4KICAgIDwvZz4KICA8L2c+CiAgPGcgaWQ9Ikdyb3VwXzEyIiBkYXRhLW5hbWU9Ikdyb3VwIDEyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjY5NSA1LjY5NSkiPgogICAgPGcgaWQ9Ikdyb3VwXzExIiBkYXRhLW5hbWU9Ikdyb3VwIDExIj4KICAgICAgPHBhdGggaWQ9IlBhdGhfNCIgZGF0YS1uYW1lPSJQYXRoIDQiIGQ9Ik0xMzMuNywxMjhhNS42OTUsNS42OTUsMCwxLDAsNS43LDUuNjk1QTUuNyw1LjcsMCwwLDAsMTMzLjcsMTI4Wm0wLDkuMjU0YTMuNTU5LDMuNTU5LDAsMSwxLDMuNTU5LTMuNTU5QTMuNTY0LDMuNTY0LDAsMCwxLDEzMy43LDEzNy4yNTRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI4IC0xMjgpIiBmaWxsPSIjMDAxNTFmIi8+CiAgICA8L2c+CiAgPC9nPgogIDxnIGlkPSJHcm91cF8xNCIgZGF0YS1uYW1lPSJHcm91cCAxNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTYuNzUzIDQuNTA5KSI+CiAgICA8ZyBpZD0iR3JvdXBfMTMiIGRhdGEtbmFtZT0iR3JvdXAgMTMiPgogICAgICA8ZWxsaXBzZSBpZD0iRWxsaXBzZV8xIiBkYXRhLW5hbWU9IkVsbGlwc2UgMSIgY3g9IjAuNzU5IiBjeT0iMC43NTkiIHJ4PSIwLjc1OSIgcnk9IjAuNzU5IiBmaWxsPSIjMDAxNTFmIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K');
    `};
`

const In = styled.a`
  ${Icon};
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi43OCIgaGVpZ2h0PSIyMy4wNSIgdmlld0JveD0iMCAwIDIyLjc4IDIzLjA1Ij4KICA8cGF0aCBpZD0iUGF0aF84OCIgZGF0YS1uYW1lPSJQYXRoIDg4IiBkPSJNNjUzLjcwOCwyMTdINjM1LjUyMmEyLjMsMi4zLDAsMCwwLTIuMywyLjNWMjM3Ljc1YTIuMywyLjMsMCwwLDAsMi4zLDIuM2gxOC4xODdhMi4zLDIuMywwLDAsMCwyLjMtMi4zVjIxOS4zMDhBMi4zLDIuMywwLDAsMCw2NTMuNzA4LDIxN1ptLTEzLjE3NCwxOC4yMzhINjM3LjQ3di05Ljg2aDMuMDY1Wm0tMS41NTItMTEuMDdhMS41MzYsMS41MzYsMCwxLDEsMS42NTMtMS41MzJBMS41MjgsMS41MjgsMCwwLDEsNjM4Ljk4MiwyMjQuMTcyWm0xMi43NDMsMTEuMDdINjQ4LjY2di01LjQ2NGMwLTEuMjctLjQ0NC0yLjEzNy0xLjU1My0yLjEzN2ExLjY4MywxLjY4MywwLDAsMC0xLjU3MywxLjE0OSwyLjIwNiwyLjIwNiwwLDAsMC0uMS43NjZ2NS42ODZoLTMuMDY1di02LjcxNGMwLTEuMjMtLjA0LTIuMjU4LS4wOC0zLjE0NWgyLjY2MWwuMTQxLDEuMzcxaC4wNmEzLjU0MSwzLjU0MSwwLDAsMSwzLjA0NS0xLjU5M2MyLjAxNiwwLDMuNTI4LDEuMzUxLDMuNTI4LDQuMjU0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYzMy4yMjUgLTIxNy4wMDQpIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=');

  ${props =>
    props.reverseColors === true &&
    css`
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi43OCIgaGVpZ2h0PSIyMy4wNSIgdmlld0JveD0iMCAwIDIyLjc4IDIzLjA1Ij4KICA8cGF0aCBpZD0iUGF0aF84OCIgZGF0YS1uYW1lPSJQYXRoIDg4IiBkPSJNNjUzLjcwOCwyMTdINjM1LjUyMmEyLjMsMi4zLDAsMCwwLTIuMywyLjNWMjM3Ljc1YTIuMywyLjMsMCwwLDAsMi4zLDIuM2gxOC4xODdhMi4zLDIuMywwLDAsMCwyLjMtMi4zVjIxOS4zMDhBMi4zLDIuMywwLDAsMCw2NTMuNzA4LDIxN1ptLTEzLjE3NCwxOC4yMzhINjM3LjQ3di05Ljg2aDMuMDY1Wm0tMS41NTItMTEuMDdhMS41MzYsMS41MzYsMCwxLDEsMS42NTMtMS41MzJBMS41MjgsMS41MjgsMCwwLDEsNjM4Ljk4MiwyMjQuMTcyWm0xMi43NDMsMTEuMDdINjQ4LjY2di01LjQ2NGMwLTEuMjctLjQ0NC0yLjEzNy0xLjU1My0yLjEzN2ExLjY4MywxLjY4MywwLDAsMC0xLjU3MywxLjE0OSwyLjIwNiwyLjIwNiwwLDAsMC0uMS43NjZ2NS42ODZoLTMuMDY1di02LjcxNGMwLTEuMjMtLjA0LTIuMjU4LS4wOC0zLjE0NWgyLjY2MWwuMTQxLDEuMzcxaC4wNmEzLjU0MSwzLjU0MSwwLDAsMSwzLjA0NS0xLjU5M2MyLjAxNiwwLDMuNTI4LDEuMzUxLDMuNTI4LDQuMjU0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYzMy4yMjUgLTIxNy4wMDQpIiBmaWxsPSIjMDIxNTFGIi8+Cjwvc3ZnPgo=');
    `};
`

const CookiesText = styled.p`
  margin: 0 30px 0 0;
  color: #fff;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 12px;
  }
`

export { Layout, ScrollDown, SocialMediaList, Fb, Ig, In, CookiesText }

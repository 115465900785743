import styled, { css } from 'styled-components'

const SmallTitle = styled.span`
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.2em;
  margin-bottom: 8px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 16px;
    margin-bottom: 16px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    margin-bottom: 24px;
  }

  ${props =>
    props.border === 'addon' &&
    css`
      &:after {
        background-color: ${props.theme.colors.primary};
      }
    `}
`

const title = css`
  position: relative;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    margin-bottom: 50px;
  }

  ${props =>
    props.border &&
    css`
      padding-bottom: 24px;

      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        padding-bottom: 44px;
      }

      @media (min-width: ${props.theme.breakpoints.xxlUp}) {
        padding-bottom: 54px;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 64px;
        height: 3px;

        @media (min-width: ${props.theme.breakpoints.lgUp}) {
          width: 98px;
        }
      }
    `}

  ${props =>
    props.border === 'white' &&
    css`
      color: #fff;
      &:after {
        background-color: #fff;
      }
    `}

  ${props =>
    props.border === 'addon' &&
    css`
      &:after {
        background-color: ${props.theme.colors.addon};
      }
    `}

  ${props =>
    props.noBorderLarge &&
    css`
      @media (min-width: ${props.theme.breakpoints.lgUp}) {
        padding-bottom: 0 !important;

        &:after {
          content: none;
        }
      }
    `}

  ${props =>
    props.center &&
    css`
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    `}
`

const H1 = styled.h1`
  ${title}
`
const H2 = styled.h2`
  ${title}
`
const H3 = styled.h3`
  ${title}
`

export { SmallTitle, H1, H2, H3 }

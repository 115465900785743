import React from 'react'
import * as Styled from './DoubleTitle.styles'

const DoubleTitle = ({
  smallTitle,
  title,
  component,
  variant,
  border,
  noBorderLarge,
  center,
}) => {
  const componentCheck = (t, c, v) => {
    switch (c) {
      case 'h1':
        return (
          <Styled.H1
            className={v}
            border={border}
            noBorderLarge={noBorderLarge}
            center={center}
          >
            {t}
          </Styled.H1>
        )
      case 'h3':
        return (
          <Styled.H3
            className={v}
            border={border}
            noBorderLarge={noBorderLarge}
            center={center}
          >
            {t}
          </Styled.H3>
        )
      default:
        return (
          <Styled.H2
            className={v}
            border={border}
            noBorderLarge={noBorderLarge}
            center={center}
          >
            {t}
          </Styled.H2>
        )
    }
  }

  return (
    <>
      {smallTitle && (
        <Styled.SmallTitle border={border} aria-hidden="true">
          {smallTitle}
        </Styled.SmallTitle>
      )}
      {componentCheck(title, component, variant)}
    </>
  )
}

export default DoubleTitle

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { document } from 'browser-monads'
import { Column, Row } from '../Grid'
import Logo from '../Logo'
import Button from '../../components/Button'
import * as Styled from './Header.styles'

const Header = ({ slug, reverseColors }) => {
  const [scrolled, setScrolled] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      setScrolled(currPos.y < 0)
    },
    [scrolled]
  )

  const toggleMenu = () => {
    setMenuOpened(!menuOpened)
    !menuOpened
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden')
  }

  useEffect(() => {
    document.body.classList.remove('overflow-hidden')
  }, [])

  return (
    <Styled.Header
      reverseColors={reverseColors || false}
      scrolled={scrolled || menuOpened}
    >
      <Row alignMiddle>
        <Column xs={8} lg={2}>
          {slug === '/' ? (
            <Logo />
          ) : (
            <Link to="/">
              <span className="sr-only">Strona główna</span>
              <Logo />
            </Link>
          )}
        </Column>
        <Column xs={4} lg={10} className="text-right">
          <Styled.Nav opened={menuOpened}>
            <Styled.NavList>
              <li className="hide-for-large">
                <Styled.NavLink to="/">Główna</Styled.NavLink>
              </li>
              <li>
                <Styled.NavLink to="/o-nas">O Nas</Styled.NavLink>
              </li>
              <li>
                <Styled.NavLink to="/uslugi">Usługi</Styled.NavLink>
              </li>
              <li>
                <Styled.NavLink to="/flota">Flota</Styled.NavLink>
              </li>
              {/* <li> */}
              {/*  <Styled.NavLink to="/najczesciej-zadawane-pytania"> */}
              {/*    Q&A */}
              {/*  </Styled.NavLink> */}
              {/* </li> */}
              <li>
                <Styled.NavLink to="/kariera">Kariera</Styled.NavLink>
              </li>
              <li>
                <Styled.NavLink to="/kontakt">Kontakt</Styled.NavLink>
              </li>
              <li>
                <Button
                  primary
                  href="mailto:wycena@nordiclogistics.pl"
                  className="button"
                >
                  Wyceń transport
                </Button>
              </li>
            </Styled.NavList>
          </Styled.Nav>
          <Styled.Toggler
            onClick={toggleMenu}
            active={menuOpened}
            aria-label="Menu"
          >
            <span className="toggler-helper" />
          </Styled.Toggler>
        </Column>
      </Row>
    </Styled.Header>
  )
}

export default Header

import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import * as Styled from './Button.styles'

const Button = ({
  primary,
  bordered,
  light,
  page,
  scroll,
  href,
  children,
  className,
  props,
}) => {
  if (page)
    return (
      <Styled.GatsbyLink
        primary={primary}
        bordered={bordered}
        light={light}
        className={className}
        to={page}
      >
        {children}
      </Styled.GatsbyLink>
    )

  if (scroll || href)
    return (
      <Styled.A
        primary={primary}
        bordered={bordered}
        light={light}
        className={className}
        href={scroll || href}
        onClick={e => {
          if (scroll) {
            e.preventDefault()
            scrollTo(scroll)
          }
        }}
      >
        {children}
      </Styled.A>
    )

  return (
    <Styled.Button
      primary={primary}
      bordered={bordered}
      light={light}
      className={className}
      {...props}
    >
      {children}
    </Styled.Button>
  )
}

export default Button

import React from 'react'
import * as Styled from './Column.styles'

export const Column = ({
  children,
  className,
  largeOrder,
  largeOffset,
  largeShrink,
  largeTextCenter,
  textCenter,
  textRight,
  noPaddingMobile,
  xs,
  sm,
  md,
  lg,
  xl,
}) => (
  <Styled.Column
    className={className}
    largeOrder={largeOrder}
    largeOffset={largeOffset}
    largeShrink={largeShrink}
    largeTextCenter={largeTextCenter}
    textCenter={textCenter}
    textRight={textRight}
    noPaddingMobile={noPaddingMobile}
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
  >
    {children}
  </Styled.Column>
)

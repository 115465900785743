import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as Styled from './Consultation.styles'
import { Column, Row } from '../Grid'
import DoubleTitle from '../../components/DoubleTitle'
import InputWrapper from '../../components/InputWrapper'
import { phoneRegExp } from '../../helpers'

const Consultation = () => {
  const [sent, setSent] = useState(false)
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "konsultacja-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  const handleSubmit = (values, setSubmitting, resetForm) => {
    const formData = new FormData()
    formData.append('phone', values.consultationPhone)

    fetch('/api/consultation.php', {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setSent(true)
        setSubmitting(false)
        resetForm({})
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <Styled.Consultation
      Tag="section"
      id="konsultacja"
      {...convertToBgImage(image)}
      preserveStackingContext
    >
      <Row alignRight>
        <Column xs={12} lg={6} xl={5}>
          <DoubleTitle
            smallTitle="Kontakt"
            title="Zapraszamy na&nbsp;bezpłatną konsultację"
          />
          <Formik
            initialValues={{
              consultationPhone: '',
              consultationTerm: false,
            }}
            enableReinitialize
            validationSchema={Yup.object({
              consultationPhone: Yup.string()
                .matches(phoneRegExp, 'Niewłaściwy format.')
                .required('Pole wymagane.'),
              consultationTerm: Yup.bool().oneOf([true], 'Pole wymagane.'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) =>
              handleSubmit(values, setSubmitting, resetForm)
            }
          >
            {({ values, errors, touched }) => (
              <Form noValidate>
                <Styled.FormWrapper>
                  <InputWrapper
                    name="consultationPhone"
                    type="text"
                    className="consultation"
                    label="Tel."
                    error={errors.consultationPhone}
                    touched={touched.consultationPhone}
                    inputValue={values.consultationPhone}
                  />
                  <Styled.Button type="submit">Wyślij</Styled.Button>
                </Styled.FormWrapper>
                {sent && <p className="lead">Wiadomość wysłana. Dziękujemy.</p>}
                <InputWrapper
                  name="consultationTerm"
                  type="checkbox"
                  className="checkbox-wrapper small white"
                  label={
                    <>
                      Wyrażam zgodę na używanie przez Nordic Logistics Polska
                      spółka z ograniczoną odpowiedzialnością z siedzibą przy
                      ul. Kasztanowa 57, 85-605 Bydgoszcz (nr KRS 604801)
                      telekomunikacyjnych urządzeń końcowych, których jestem
                      użytkownikiem, dla celów marketingu bezpośredniego zgodnie
                      z art. 172 ustawy z dnia 16 lipca 2004 r. Prawo
                      telekomunikacyjne. Przyjmuję do wiadomości, że zgodę tę
                      mogę w każdej chwili odwołać, wysyłając wiadomość na adres
                      e-mail:{' '}
                      <a href="mailto: biuro@nordiclogistics.pl">
                        biuro@nordiclogistics.pl
                      </a>
                    </>
                  }
                  error={errors.consultationTerm}
                  touched={touched.consultationTerm}
                  inputValue={values.consultationTerm}
                />
              </Form>
            )}
          </Formik>
        </Column>
      </Row>
      <StaticImage
        className="show-for-large"
        src="../../images/konsultacja.png"
        alt=""
      />
    </Styled.Consultation>
  )
}

export default Consultation
